<form [formGroup]="insuranceForm" (ngSubmit)="onSubmit()">
  <mat-dialog-content>
    <div class="row">
      <div *ngFor="let key of insuranceModelKeys; let i = index">

        <mat-form-field *ngIf="!key.includes('date')" appearance="outline">
          <mat-label style="text-transform: capitalize;">{{ key }}</mat-label>
          <input matInput [formControlName]="key">
        </mat-form-field>

        <mat-form-field *ngIf="key.includes('date')" appearance="outline">
          <mat-label style="text-transform: capitalize;">{{ key }}</mat-label>
          <input matInput [matDatepicker]="picker" [formControlName]="key">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-button mat-dialog-close>Close</button>
    <button mat-raised-button color="primary" type="submit">{{data ? 'Update' : 'Save'}}</button>
  </mat-dialog-actions>
</form>
