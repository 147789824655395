import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {InsuranceListComponent} from "./insurances/insurance-list/insurance-list.component";
import {ClientListComponent} from "./insurances/client-list/client-list.component";

const routes: Routes = [
  {path: '', pathMatch: "full", redirectTo: "insurances"},
  {path: 'insurances', component: InsuranceListComponent},
  {path: 'clients', component: ClientListComponent},
  {
    path: 'details/:index',
    loadComponent: () => import('./insurances/insurance-view/insurance-view.component').then(m => m.InsuranceViewComponent)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
