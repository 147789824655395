import {Injectable} from '@angular/core';
import {InsuranceModel} from "../models/insurance.model";
import {catchError, Observable, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InsuranceService {

  insurances: InsuranceModel[] = [];

  constructor(private http: HttpClient) {
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    })
  };

  public getAllInsurances(): Observable<InsuranceModel[]> {
    const url = '/api/Insurance/GetAll';
    const localUrl = '../assets/insurances_data.json';
    return this.http.get<InsuranceModel[]>(environment.isProduction ? url : localUrl);
  }

  public deleteInsurance(insuranceIdToRemove: number) {
    const url = '/api/Insurance/Delete';
    const localUrl = '../assets/insurances_data.json';

    // Create HttpParams object to pass parameters
    let params = new HttpParams().set('id', insuranceIdToRemove);

    return this.http.delete<InsuranceModel>(url, {params});
  }

  /** POST: add a new hero to the database */
  addInsurance(newInsurance: InsuranceModel): Observable<InsuranceModel> {
    const url = '/api/Insurance/Save';
    const { id, ...insuranceWithoutId } = newInsurance;
    insuranceWithoutId.isPaid = false;
    return this.http.post<InsuranceModel>(url, insuranceWithoutId, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
