import {CommonModule} from '@angular/common';
import {InsuranceService} from "../insurance.service";
import {InsuranceCardComponent} from "../insurance-card/insurance-card.component";
import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule, Sort} from "@angular/material/sort";

import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from "@angular/material/icon";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {InsuranceModel} from "../../models/insurance.model";
import {MatCardModule} from "@angular/material/card";
import {MatDialog, MatDialogModule} from '@angular/material/dialog';

import {InsuranceDialogComponent} from "../insurance-dialog/insurance-dialog.component";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";

@Component({
  selector: 'app-insurance-list',
  standalone: true,
  imports: [CommonModule, InsuranceCardComponent, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule, MatIconModule,
    FormsModule, MatButtonModule, MatCardModule, MatDialogModule, MatToolbarModule, MatDatepickerModule, MatNativeDateModule],
  templateUrl: './insurance-list.component.html',
  styleUrls: ['./insurance-list.component.css']
})
export class InsuranceListComponent implements OnInit, AfterViewInit {

  dataSource!: MatTableDataSource<any>;
  displayedColumns: string[] = ['name', 'insuranceCompany', 'dateFrom', 'dateTo', 'amount', 'details', 'actions']; // Ensure column IDs match
  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  expandedElement: InsuranceModel | null | undefined;

  constructor(readonly insuranceService: InsuranceService,
              private _liveAnnouncer: LiveAnnouncer,
              public dialog: MatDialog) {

  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, row: any): void {
    console.log(row)
    this.dialog.open(InsuranceDialogComponent,
      {
        data: row,
        // width: '100%',
        enterAnimationDuration,
        exitAnimationDuration,
      }).afterClosed().subscribe(result => {
      //simple close dialog
      this.getData();
    });
  }


  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngOnInit(): void {
    this.getData();
  }

  private getData() {
    this.insuranceService.getAllInsurances().subscribe(data => {
      console.log(data)
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ngAfterViewInit(): void {
  }

  applyFilter(event: any) {
    const filterValue = (event?.target as HTMLInputElement)?.value || '';
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  deleteInsurance(id: number) {
    this.insuranceService.deleteInsurance(id).subscribe({
      next: value => {
        alert("Insurance removed");
        // this.insuranceService.getAllInsurances()
      }, error: console.log
    });
  }
}
