import {Injectable} from '@angular/core';
import {InsuranceModel} from "../models/insurance.model";
import {catchError, Observable, throwError} from "rxjs";
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from "@angular/common/http";
import {environment} from '../../environments/environment';
import {ClientModel} from "../models/client.model";

@Injectable({
  providedIn: 'root'
})
export class ClientService {

  clientModels: ClientModel[] = [];

  constructor(private http: HttpClient) {
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'my-auth-token'
    })
  };

  public getAllClients(): Observable<ClientModel[]> {
    const url = '/api/Client/GetAll';
    const localUrl = '../assets/clients_data.json';
    return this.http.get<ClientModel[]>(environment.isProduction ? url : localUrl);
  }

  public deleteClient(clientIdToRemove: any): Observable<ClientModel[]> {
    const url = '/api/Client/Delete';
    const localUrl = '../assets/clients_data.json';
    // return this.http.delete<ClientModel[]>(environment.isProduction ? url : localUrl);

    // Create HttpParams object to pass parameters
    let params = new HttpParams().set('id', clientIdToRemove);

    return this.http.delete<ClientModel[]>('/api/Client/Delete', {params});
  }



  /** POST: add a new hero to the database */
  addClient(clientModel: ClientModel): Observable<ClientModel> {
    // newInsurance.client.insurances= [];
    return this.http.post<ClientModel>('/api/Client/Save', clientModel, this.httpOptions)
      .pipe(
        catchError(this.handleError)
      );
  }

  private handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
