import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogModule,} from '@angular/material/dialog';
import {MatButtonModule} from "@angular/material/button";
import {MatFormFieldModule} from "@angular/material/form-field";
import {FormBuilder, FormGroup, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgForOf, NgIf} from "@angular/common";
import {MatInputModule} from "@angular/material/input";
import {InsuranceService} from "../insurance.service";
import {MatListModule} from "@angular/material/list";
import {DialogRef} from "@angular/cdk/dialog";
import {MatDatepickerModule} from "@angular/material/datepicker";

@Component({
  selector: 'app-insurance-dialog',
  templateUrl: './insurance-dialog.component.html',
  styleUrls: ['./insurance-dialog.component.css'],
  standalone: true,
  imports: [MatDialogModule, MatButtonModule, MatFormFieldModule, ReactiveFormsModule, FormsModule, NgForOf, MatInputModule, MatListModule, MatDatepickerModule, MatDatepickerModule, NgIf]
})
export class InsuranceDialogComponent implements OnInit {
  insuranceForm!: FormGroup;
  insuranceModelKeys!: string[];

  constructor(private fb: FormBuilder,
              private insuranceService: InsuranceService,
              private _dialogRef: DialogRef<InsuranceDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.insuranceModelKeys = Object.keys(this.generateEmptyModel());
    this.initForm();
    this.insuranceForm.patchValue(this.data);
  }

  initForm() {
    const formGroupConfig: any = {};
    this.insuranceModelKeys?.forEach(key => {
      formGroupConfig[key] = [null];
    });
    this.insuranceForm = this.fb.group(formGroupConfig);
  }

  generateEmptyModel() {
    return {
      // id: null,
      name: '',
      details: '',
      dateFrom: '',
      dateTo: '',
      insuranceCompany: '',
      type: '',
      amount: null,
      isPaid: null,
      // selected: false,
      // clientId: null,
      //client: null
    };
  }

  onSubmit() {
    console.log(this.insuranceForm.valid)
    if(this.data){
      //PUT
      this._dialogRef.close();
    }else{
      // ADD NEW
      // Handle form submission
      console.log(this.insuranceForm?.value);

      // this.insuranceService.addInsurance(this.insuranceForm?.value)

      this.insuranceService.addInsurance(this.insuranceForm?.value)
        .subscribe(newInsurance => {

            console.log("tutaj odswiezyc liste");
            console.log(newInsurance);
            this._dialogRef.close();
          },
          error => {
            alert('lipa zamykam');
            this._dialogRef.close();
          }
        );
    }



  }
}
