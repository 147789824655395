import {AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatTableModule} from "@angular/material/table";
import {LiveAnnouncer} from "@angular/cdk/a11y";
import {MatDialog, MatDialogModule} from "@angular/material/dialog";
import {MatPaginator, MatPaginatorModule} from "@angular/material/paginator";
import {MatSort, MatSortModule, Sort} from "@angular/material/sort";
import {ClientService} from "../client.service";
import {CommonModule} from "@angular/common";
import {InsuranceCardComponent} from "../insurance-card/insurance-card.component";
import {MatFormFieldModule} from "@angular/material/form-field";
import {MatInputModule} from "@angular/material/input";
import {MatIconModule} from "@angular/material/icon";
import {FormsModule} from "@angular/forms";
import {MatButtonModule} from "@angular/material/button";
import {MatCardModule} from "@angular/material/card";
import {MatToolbarModule} from "@angular/material/toolbar";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {MatNativeDateModule} from "@angular/material/core";
import {ClientDialogComponent} from "../client-dialog/client-dialog.component";
import {ClientModel} from "../../models/client.model";

@Component({
  selector: 'app-client-list',
  standalone: true,
  imports: [CommonModule, InsuranceCardComponent, MatTableModule, MatPaginatorModule, MatSortModule, MatFormFieldModule, MatInputModule, MatIconModule,
    FormsModule, MatButtonModule, MatCardModule, MatDialogModule, MatToolbarModule, MatDatepickerModule, MatNativeDateModule],
  templateUrl: './client-list.component.html',
  styleUrls: ['./client-list.component.css']
})
export class ClientListComponent implements OnInit, AfterViewInit {

  dataSource!: MatTableDataSource<any>;

  displayedColumns: string[] = ['fullName', 'firstName', 'phoneNumber', 'actions']; // Ensure column IDs match

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor(readonly clientService: ClientService,
              private _liveAnnouncer: LiveAnnouncer,
              private changeDetectorRefs: ChangeDetectorRef,
              public dialog: MatDialog) {

  }

  openDialog(enterAnimationDuration: string, exitAnimationDuration: string, row: any): void {
    console.log(row)
    this.dialog.open(ClientDialogComponent,
      {
        data: row,
        // width: '100%',
        enterAnimationDuration,
        exitAnimationDuration,
      }).afterClosed().subscribe(result => {
      //simple close dialog
      this.getData();
    });
  }

  deleteInsurance(row: ClientModel) {
    this.clientService.deleteClient(row.id)
      .subscribe(data => {
        this.getData();
      });
  }

  applyFilter(event: any) {
    const filterValue = (event?.target as HTMLInputElement)?.value || '';
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  ngOnInit(): void {
    this.getData();

  }

  getData() {
    this.clientService.getAllClients()
      .subscribe(data => {
        console.log(data)
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;

        this.changeDetectorRefs.detectChanges();
      });
  }

  announceSortChange(sortState: Sort) {
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  deleteClient(id: number) {
    // this.insuranceService.deleteInsurance(id).subscribe({
    //   next: value => {
    //     alert("Insurance removed");
    //     // this.insuranceService.getAllInsurances()
    //   }, error: console.log
    // });
  }

  ngAfterViewInit(): void {
  }
}
