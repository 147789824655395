<mat-toolbar color="primary">
  <button mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon>menu</mat-icon>
  </button>
  <span>ASDF</span>
  <span class="example-spacer"></span>
  <input matInput (keyup)="applyFilter($event)" placeholder="Find in any column" #filterName>
  <button matSuffix mat-icon-button aria-label="Clear" (click)="filterName.value = ''; applyFilter(filterName.value)">
    <mat-icon>close</mat-icon>
  </button>

  <button mat-raised-button color="warn"
          (click)="openDialog('0ms', '0ms', null)">
    <mat-icon>add</mat-icon>
    Add client
  </button>
</mat-toolbar>


<div class="example-container mat-elevation-z8">
  <mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>

    <!-- Define your columns here -->
    <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column">
      <mat-header-cell *matHeaderCellDef mat-sort-header>{{ column | titlecase }}</mat-header-cell>

      <div *ngIf="column != 'actions'">
        <mat-cell *matCellDef="let row">{{ row[column] }}</mat-cell>
      </div>
      <div *ngIf="column == 'actions'">
        <mat-cell *matCellDef="let row">
          <button mat-icon-button
                  aria-label="Example icon button with a vertical three dot icon"
                  (click)="openDialog('0ms', '0ms', row)">
            <mat-icon>edit</mat-icon>
          </button>
          <button mat-icon-button color="warn"
                  aria-label="Example icon button with a vertical three dot icon"
                  (click)="deleteInsurance(row)">
            <mat-icon>delete</mat-icon>
          </button>
          <button mat-icon-button aria-label="Example icon button with a vertical three dot icon">
            <mat-icon>more_vert</mat-icon>
          </button>
        </mat-cell>
      </div>
    </ng-container>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" [attr.colspan]="displayedColumns.length">No data found</td>
    </tr>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
